<template>
  <div class="search-wrap">
    <input
      v-model="inputValue"
      class="search-input"
      :placeholder="$t('dao.search')"
      translate="no"
    />
    <div class="search-icon" @click="handleSearch">
      <img class="icon" src="../../../assets/images/search.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: ['value'],
  data () {
    return {

    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleSearch () {
      this.$emit('handleSearch')
    }
  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.search-wrap {
  display: flex;
  .search-input {
    flex: 1;
    border: none;
    height: 36px;
    line-height: 35px;
    padding-left: 15px;
    background: $bg-color1;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px 0px 0px 8px;
    &::-webkit-input-placeholder {
      color: #3f3a61;
      font-size: $font-12;
    }
  }
  .search-icon {
    margin-left: 5px;
    width: 51px;
    height: 36px;
    background: $bg-color1;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0px 8px 8px 0px;
    color: linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
