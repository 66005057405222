<template>
  <div class="connect-wrapper">
    <span class="connect-text">{{ common.link }}：</span>
    <span class="connect-box" v-if="myAccount" translate="no">{{
      $gbUtils.formatAddress(myAccount)
    }}</span>
    <span class="connect-box" v-else @click="getAcount">{{
      common.notLinked
    }}</span>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import config from '@/config/app.config.js'

export default {
  name: '',
  data () {
    return {
      message: '', // 随机数
      messageHash: '', // 哈希后的数据
      signature: '', // 签名之后的字符串 长度:132,0x开头
      creatFlag: false, // 创建标识，填写邀请码
      inviteCode: '',
      acount: ''
    }
  },
  computed: {
    ...mapState(['myAccount']),
    common () {
      return this.$t('common')
    }
  },
  watch: {
    myAccount (val) {
      this.changeAccount(val)
    }
  },
  methods: {
    ...mapActions(['changeAccount']),
    // 钱包监听
    listenWallet () {
      // 账号切换
      this.$web3.ethereum.on('accountsChanged', (accounts) => {
        console.log('accountsChanged')
        this.changeAccount(accounts[0])
      })
      // 断开链接
      this.$web3.ethereum.on('disconnect', res => {
        console.log('disconnect')
        this.changeAccount()
        // this.toLogin()
      })
      this.$web3.ethereum.on('chainChanged', (chainId) => {
        console.log(chainId)
        if (chainId !== config.chainId) {
          this.changeAccount()
        }
      })
    },
    async getAcount () {
      const resp = await this.$web3.connectWallet()
      if (!resp.success) {
        return this.$toast(resp.message)
      }
      this.acount = resp.result
      this.changeAccount(this.acount)
    }
  },
  mounted () {
    this.getAcount()
    this.listenWallet()
  }
}
</script>

<style scoped lang="scss">
.connect-wrapper {
  font-size: 12px;
  color: #d4cbc2;
  .connect-box {
    display: inline-block;
    text-align: center;
  }
}
</style>
