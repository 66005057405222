<template>
  <div class="dao-item" @click="handleShowJoin">
    <div class="logo">
      <img :src="dataSource.params[0]" alt="" />
    </div>
    <div class="info">
      <div class="header">
        <div class="name" v-if="lang === 'ZH'">{{ dataSource.params[1] }}</div>
        <div class="name" v-else>{{ dataSource.params[2] }}</div>
        <div class="members">
          {{ $t('dao.joined') }}{{ dataSource.memberCount }}
        </div>
      </div>
      <div class="content ellipsis-3" v-if="lang === 'ZH'">
        {{ dataSource.params[3] }}
      </div>
      <div class="content ellipsis-3" v-else>{{ dataSource.params[4] }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: '',
  props: ['dataSource', 'index'],
  data () {
    return {

    }
  },
  computed: {
    ...mapState(['lang'])
  },
  methods: {
    handleShowJoin () {
      this.$emit('show', this.index)
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.dao-item {
  padding: 20px;
  margin-top: 10px;
  height: 130px;
  background: $bg-color1;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  color: $main-color1;
  background-size: 100% 100%;
  .logo {
    width: 39px;
    height: 39px;
    margin-right: 18px;
    img {
      width: 39px;
      height: 39px;
      object-fit: scale-down;
    }
  }
  .info {
    flex: 1;
  }
  .header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      font-weight: 600;
      color: $base-color;
      font-size: $font-16;
    }
    .members {
      height: 19px;
      background: $linear-bg3;
      border-radius: 9px;
      color: $main-color1;
      line-height: 19px;
      padding: 0 15px;
    }
  }
  .content {
    color: $color-gray;
    line-height: 17px;
  }
}
</style>
