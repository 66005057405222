<template>
  <div class="dao-page">
    <Header />
    <Search v-model="searchInfo" @handleSearch="handleSearch" />
    <template v-if="list.length > 0">
      <DaoItem
        v-for="(item, index) in list"
        :key="item.token"
        :dataSource="item"
        :index="index"
        @show="handleJoinDaoShow"
      ></DaoItem>
    </template>
    <DaoDetail
      v-if="detailShow"
      :dataSource="selectDao"
      @close="handleShowDetail"
      :lang="lang"
    ></DaoDetail>
    <CommomPop v-if="joinDaoShow" @close="handleJoinDaoShow">
      <div class="title">
        {{ $t('dao.hopJoin') }}
        <span v-if="lang === 'ZH'">"{{ selectDao.params[1] }}"</span>
        <span v-else>"{{ selectDao.params[2] }}"</span>
        ?
      </div>
      <div class="button-group">
        <div class="btn3" @click="handleJoinDaoShow">
          <div class="btn2">
            {{ $t('common.back') }}
          </div>
        </div>
        <VanButton
          :loading="joinDaoLoading"
          color="$linear-bg3"
          class="btn"
          @click="handleJoinDao"
        >
          {{ $t('common.confirm') }}
        </VanButton>
      </div>
    </CommomPop>
    <Empty v-if="list.length <= 0" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from './components/Header.vue'
import Search from './components/Search.vue'
import DaoItem from './components/DaoItem.vue'
import CommomPop from '../../components/common/CommomPop.vue'
import ContractDao from '@/contracts/contractDao'
import DaoDetail from './components/DaoDetail.vue'
import Empty from '../../components/common/Empty.vue'
export default {
  name: '',
  computed: {
    ...mapState(['lang'])
  },
  components: { Header, Search, DaoItem, CommomPop, DaoDetail, Empty },
  data () {
    return {
      searchInfo: '',
      joinDaoShow: false,
      detailShow: false,
      originList: [],
      list: [],
      selectDao: {},
      daoAt: 0,
      joinDaoLoading: false
    }
  },
  methods: {
    handleShowDetail () {
      this.detailShow = !this.detailShow
    },
    async handleJoinDaoShow (index) {
      if (!this.joinDaoShow) {
        this.daoAt = this.list[index].daoAt
        this.selectDao = this.list[index]
        this.$emit('changeLoading', true)
        this.$emit('changeLoadingIconType', 0)
        const isDao = await this.userDAao()
        this.$emit('changeLoading', false)
        if (isDao) {
          this.handleShowDetail()
        } else {
          this.joinDaoShow = true
        }
      } else {
        this.joinDaoShow = false
      }
    },
    async userDAao () {
      const resp = await ContractDao.userDao(this.daoAt)
      if (!resp.success) return
      return resp.result
    },
    async getList () {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      const resp = await ContractDao.daoList()
      this.$emit('changeLoading', false)
      if (!resp.success) return
      resp.result.forEach((item, index) => {
        const obj = { ...item }
        obj.daoAt = index
        obj.daoName = this.lang === 'ZH' ? item.params[1] : item.params[2]
        obj.daoDesc = this.lang === 'ZH' ? item.params[3] : item.params[4]
        this.list.push(obj)
      })
      this.list = [...this.list].sort((a, b) => {
        return +b.memberCount - +a.memberCount
      })
      this.originList = this.list || []
    },
    async handleJoinDao () {
      this.joinDaoLoading = true
      const resp = await ContractDao.daoJoin(this.daoAt)
      this.joinDaoLoading = false
      if (!resp.success) return
      this.handleJoinDaoShow()
      // 成功后，成员+1
      this.selectDao.memberCount = +this.selectDao.memberCount + 1
      this.$toast('success')
    },
    handleSearch () {
      if (!this.searchInfo) {
        this.list = this.originList || []
        return
      }
      // const str = ['', ...this.searchInfo, ''].join('.*')
      const reg = new RegExp(this.searchInfo, 'i')
      this.list = this.originList.filter(
        (item) => reg.test(item.daoName) || reg.test(item.daoDesc)
      )
    },
    init () {
      this.getList()
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.dao-page {
  padding: 0 15px;
  font-size: 12px;
  .title {
    color: $main-color1;
  }
  .button-group {
    margin-top: 35px;
    padding: 0 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      background: $linear-bg3;
      height: 39px;
      border-radius: 8px;
      width: 45%;
    }
    .btn2 {
      height: 39px;
      border-radius: 8px;
      background: #35353b;
      line-height: 39px;
      color: $main-color1;
    }
    .btn3 {
      width: 45%;
      background: linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
      background: -webkit-linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
      background: -o-linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
      padding: 1px;
      border-radius: 8px;
    }
  }
}
</style>
