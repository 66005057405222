<template>
  <div class="header-wrapper">
    <div class="connect">
      <div class="bsc" translate="no">BSC</div>
      <ConnectWalletVue @loginSuccess="loginSuccess" />
    </div>
    <ChangeLanguageVue />
  </div>
</template>

<script>
import ConnectWalletVue from '../../../components/common/ConnectWallet.vue'
import ChangeLanguageVue from '../../../components/common/ChangeLanguage.vue'
export default {
  components: { ConnectWalletVue, ChangeLanguageVue },
  methods: {
    loginSuccess () {
      this.$parent.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 0;
  .connect {
    display: flex;
    align-items: center;
    .bsc {
      color: $base-color;
      background: linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
      width: 46px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-radius: 12px;
      margin-right: 15px;
    }
  }
}
</style>
